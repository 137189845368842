/*
 *  Document   : bootstrap.js
 *  Author     : pixelcave
 *  Description: Import global dependencies
 *
 */

/*
 ********************************************************************************************
 *
 * If you would like to use webpack to handle all required core JS files, you can uncomment
 * the following imports and window assignments to have them included in the compiled
 * oneui.app.min.js as well.
 *
 * After that change, you won't have to include oneui.core.min.js in your pages any more
 *
 *********************************************************************************************
 */

import Swal from 'sweetalert2';

// ..and assign to window the ones that need it
window.swal = Swal;
